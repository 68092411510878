import React from 'react'
import { Link } from 'gatsby'
import { Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { Button, SendEnquiryBlock } from '/src/components/common'

const GreenstarPage = () => {
  return (
    <Layout>
      <Row className="mt-5 mb-5">
        <Col xs={12}>
          <div className="mb-5">
            <Link className="back-to-link" to='/resources'>
              <FontAwesomeIcon icon={faArrowLeft} />
              Back to resources
            </Link>
          </div>

          <GreenstarPageList />
        </Col>
      </Row>
      <Row className="mb-5">
        <Col xs={12}>
          <SendEnquiryBlock />
        </Col>
      </Row>
    </Layout>
  )
}

const GreenstarPageList = () => {
  return (
    <div className="certificate-group-list">
      <Row>
        <div className="certificate-group mb-5">
          <Row>
            <Col sm={4}>
              <div className="title-container">
                <h3 className="title">Sustainability</h3>
              </div>
            </Col>
            <Col sm={8}>
            <div className="certificate-list">
              <Row>
                <a className="certificate-list-item" target="_blank" href="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/Green+Star+Credits+%E2%80%93+Responsible+Steel+Supply.pdf">
                  <div className="title-container">
                    <h5 className="title">Orrcon Steel and the Green Star Scheme</h5>
                  </div>
                  <div className="content info mb-4">
                    <p>The Green Star scheme, administered by the Green Building Council of Australia
                      (https://new.gbca.org.au/rate/green-star/), awards points for a range of categories that contribute to the planning,
                      construction and operation of an environmentally sustainable building. This includes energy use, transportation,
                      water use, emissions, and materials. A building, fit-out or community can be assessed and rated on a star scale
                      from 1 (minimum practice) to 5 (Australian Excellence) or 6 (World Leadership).</p>
                  </div>
                  <div className="content info mb-4">
                    <a className="button primary" target="_blank" href="https://orrcon-static-assets.s3.ap-southeast-2.amazonaws.com/Green+Star+Credits+%E2%80%93+Responsible+Steel+Supply.pdf">
                      View Document
                    </a>
                  </div>
                </a>
              </Row>
            </div>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  )
}

export default GreenstarPage

export const Head = () => <title>Greenstar Rating | Orrcon Steel</title>
